import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

export const routes: Routes = [

    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        loadChildren: () => import('src/app/pages/home/home.module').then(m => m.HomeModule),
        data: {
            breadcrumb: 'Home',
        }
    },
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
