import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

export const routes: Routes = [

  { path: '', redirectTo: '', pathMatch: 'full' },
  {
    path: '',  loadChildren: () => import('../app/pages/pages.module').then(m => m.PagesModule),
  },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules,
});
